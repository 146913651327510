import React from 'react'

const Pricing = () => {
  return (
    <section className="receptionPrice__content">

    </section>
  )
}

export default Pricing