import * as React from 'react';
import Layout from "../../../Components/Layout"
import { FooterComponent } from '../../../Components/FooterComponent';
import ReceptionPricing from '../../../modules/ReceptionSuite/Pricing';
import HeaderComponent from '../../../Components/HeaderComponent';
import Seo from '../../../Components/Seo';

const index = () => {
  return (
    <>
      <Seo title="Pricing | Reception Management Suite"/>
      <Layout name="receptionPricing"  hasProducts={true} >
        <title>Reception Suite Pricing</title>
        <HeaderComponent hasPrice loginUrl="/login"/>
        <ReceptionPricing  />
        <FooterComponent />
      </Layout>
    </>
  )
}

export default index